import styles from './LandingView.module.scss';
import React from "react";
import { LogoSVG } from '../svgs/LogoSVG';

export function LandingView() {

  return (
    <div className={styles.mainContainer}>
      <img src={require('../../assets/imgs/app_mockup.png')} className={styles.imagePreview} />
      <LogoSVG className={styles.navLogo} />
      <div className={styles.landingTextWrapper}>
        <p className={styles.landingTitle}>Create, communicate, share, & preserve your most precious lifetime <span className={styles.turqoiseText}>memories</span></p>
        <p className={styles.landingSubText}>LuvBuckets acts as a virtual time capsule to preserve love and legacies by allowing users to stay forever connected with friends and loved ones, not only throughout life, but for generations to come.</p>
        <p className={styles.landingAppText}>Available on both iOS and Android</p>
        <div className={styles.landingAppButtonsWrapper}>
          <img src={require('../../assets/imgs/google_play_store_button.png')} className={styles.googlePlayButton} onClick={() => {
            window.open('https://play.google.com/store/apps/details?id=com.luvbuckets.app');
          }} />
          <img src={require('../../assets/imgs/app_store_button.png')} className={styles.appStoreButton} onClick={() => {
            window.open('https://apps.apple.com/us/app/id/1519980606');
          }} />
          <p className={styles.landingWebFeaturesComingSoonText}>More website features coming soon.</p>
        </div>
      </div>
    </div>
  );
}