import { store } from '../store';
import { apiRequest } from '../helpers/retryReq';

const apiUrl = process.env.REACT_APP_API_URL || '';

let token: string;

const coreUserQuery = `{
    id
    first
    email
    signedUrlProfileImageThumb
    last
    theme
    tokens {
      token
    }
  }`

const userInfoQuery = `query UserInfo {
  userInfo ${coreUserQuery}
}`;


const registerUserGraphQlQuery = `mutation CreateUser($user:UserInputCreate!) {
  createUser(user:$user) ${coreUserQuery}
}`;

const signInUserQuery = `mutation signIn($email: String!, $password: String!) {
  signIn(email:$email, password: $password) ${coreUserQuery}
}`;

const validateTokenQuery = `mutation validateToken($token: String!) {
  validateToken(token:$token) ${coreUserQuery}
}`;

const logoutUserQuery = `mutation logOut {
  logOut
}`;

const forgotPasswordQuery = `mutation forgotPassword($userEmail: String!) {
  forgotPassword(userEmail: $userEmail)
}`;

const resetPasswordQuery = `mutation resetPassword($password: String!) {
  resetPassword(password: $password)
}`;

export interface UserCreateForm {
  first: string;
  last: string;
  email: string;
  countryCode?: string;
  phone?: string;
  password: string;
}

export interface UserSignInForm {
  email: string;
  password: string;
}

export function isAuthenticated() {
  return !!token;
}

export function getToken() {
  return token;
}

const reqInstances = {
  loginUser: new apiRequest(),
  registerUser: new apiRequest(),
  validateToken: new apiRequest(),
  logout: new apiRequest(),
  forgotPassword: new apiRequest(),
  resetPassword: new apiRequest(),
};

export function getUserInfo(token: string) {
  return fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      query: userInfoQuery,
      variables: {}
    })
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
}

export function registerUser(userData: UserCreateForm) {
  let theme = (store.getState()?.theme?.TYPE || 'default_theme').toLowerCase();
  return reqInstances.registerUser.init({
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: registerUserGraphQlQuery,
      variables: { user: { email: userData.email, password: userData.password, first: userData.first, last: userData.last, status: 'pending', theme: theme, inAppPurchases: [] } }
    })
  });
}

export function loginUser(credentials: UserSignInForm) {
  return reqInstances.loginUser.init({
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: signInUserQuery,
      variables: { email: credentials.email, password: credentials.password }
    }),
  });
}

export function validateToken(token: string) {
  return reqInstances.validateToken.init({
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: validateTokenQuery,
      variables: {
        token,
      },
    }),
  });
}

export function logout() {
  const { user } = store.getState();

  return reqInstances.logout.init(
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: logoutUserQuery,
        variables: {},
      }),
    },
    true
  );
}

export function forgotPassword(email: string) {
  return reqInstances.forgotPassword.init({
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: forgotPasswordQuery,
      variables: { userEmail: email },
    }),
  });
}

export function resetPassword(password: string) {
  return reqInstances.resetPassword.init(
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: resetPasswordQuery,
        variables: { password },
      }),
    },
    true
  );
}
