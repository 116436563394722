import { SELECT_ASSET, ADD_RECIPIENT, UPDATE_RECIPIENT } from '../actions/uploads';
import { Memory } from '../services/MemoriesService';

export interface Asset {
  editModalOpen?: boolean;
  imageViewModalOpen: boolean;
  id?: string | number;
  fileName: string;
  assetType: string;
  location: string;
  mimeType: string;
  recipients: Array<any>;
  path: string;
  signedUrl: string | null;
  signedUrlThumb: string | null;
  status: string;
  memories?: Memory[];
  title: string;
  userId: number;
}

export interface UploadsAction {
  type: string;
  asset?: Asset;
  recipient?: any;
}

const initState = {
  signedUrl: null,
  recipients: [],
};

export function uploadsReducer(state = initState, action: UploadsAction) {
  switch (action.type) {
    case SELECT_ASSET:
      return { ...state, ...action.asset };
    case ADD_RECIPIENT:
      const stateCopy = JSON.parse(JSON.stringify(state));
      if (!stateCopy.recipients) stateCopy.recipients = [];
      stateCopy.recipients.push(action.recipient);
      return stateCopy;
    case UPDATE_RECIPIENT:
      const updatedState = JSON.parse(JSON.stringify(state));
      if (action.recipient.index) {
        updatedState.recipients[action.recipient.index] = action.recipient;
      }
      return updatedState;
    default:
      return state;
  }
}
