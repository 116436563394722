import React, { useState, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileVideo, faFileAudio, faTrash, faPlay, faPause, faExpand, faExpandArrowsAlt, faExpandAlt } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectAsset } from '../../actions/uploads';
import styles from './UploadElem.module.scss';
import { deleteAsset } from '../../services/AssetService';
import AudioPlaybackElement from '../Audio/AudioPlaybackElem';
import { setSelectedVideoUri } from '../../actions/videoUri';
import { State } from '../../store';
import { Asset } from '../../reducers';
import { PlayVideoSVG } from '../svgs/PlayVideoSVG';
import { AudioWavesSVG } from '../svgs/AudioWavesSVG';

export function UploadElem(props: any) {
  const dispatch = useDispatch();
  const history = useHistory();
  const asset: Asset = props.media;
  const theme = useSelector((state: State) => state.theme, shallowEqual);

  return (
        <div className={styles.uploadWrapper}>
            {asset.assetType === "video" ? <div className={styles.memoryVideoAssetPreview} onClick={viewVideo}>
              <img src={asset.signedUrlThumb || ''} alt="" className={styles.thumbnail}/>
              <div className={styles.overlay} />
              <PlayVideoSVG fill={"#FFFFFF"} className={styles.playIcon} />
            </div> : <></>}
            {asset.assetType === "audio" ? <div className={styles.memoryAudioAssetPreview}>
              <AudioPlaybackElement playbackFill={"#FFFFFF"} className={styles.playbackIcon} uri={asset.signedUrl} />
              <AudioWavesSVG className={styles.audioWavesIcon} />
            </div> : <></>}
            {asset.assetType === "image" ? <div className={styles.memoryImageAssetPreview} onClick={viewImage}>
              <img src={asset.signedUrlThumb || ''} alt="" className={styles.thumbnail}/>
              <div className={styles.overlay} />
              <FontAwesomeIcon
                icon={faExpandAlt}
                className={styles.expandIcon} />
            </div> : <></>}
            <p className={styles.uploadFileName}>{props.media.title}</p>
            {/* {props.media.mimeType !== 'video/quicktime' && (
              <AudioPlaybackElement className={styles.uploadPlaybackIcon} uri={props.media.signedUrl} />
            )} */}
        </div>
  );

  function viewVideo() {
    history.push('/viewVideo', { uri: asset.signedUrl })
  }

  function viewImage() {
    history.push('/viewImage', { uri: asset.signedUrl });
  }
}

UploadElem.propTypes = {
  media: PropTypes.object,
};
